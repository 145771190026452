$black: #000000;
$white: #ffffff;
$dark_grey:  #2b2d42;
$light_grey:  #8d99ae;
$yellow: yellow;
$orange: #D15D2C;
$orange_v01: #f4a261;
$orange_v02: #ffdab9;
$black_cool: rgb(35,34,40);

$imgpath:"/src/assets";
