.steps{
    // background-image: url('/assets/bg-pattern.png');
    // background-position: center;
    // background-repeat: repeat;
    position: relative; // nic
    background-color: $white;
    height: 50vh; // Fallback option
    height: calc(var(--vh, 1vh) * 50);
    .row{
        @include media("<=tablet") {
        flex-direction: column;
        }
        .step{
            position: relative;
            // background: $white;
            .step-details{
                // width: 33.3333vw;
                height: 50vh;
                height: calc(var(--vh, 1vh) * 50);
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 32px 0;
                box-sizing: border-box;
                z-index: 1;
                position: relative;
                span {
                    margin: 20px 40px 0px 0px;
                    font-size: 1.0rem;
                    opacity: 0.8;
                    color: $light_grey;
                    font-weight: 400;
                    @include media("<=desktop", ">tablet") {
                      font-size: 1.4rem;
                    }
                    @include media("<=phone") {
                      font-size: 1.2rem;
                      line-height: 2.2rem;
                    }
                  }
                  h3 {
                    font-size: 1.2rem;
                    line-height: 3.4rem;
                    width: 85%;
                    margin-top: 16px;
                    color: $dark_grey;
                    @include media("<=desktop", ">tablet") {
                      font-size: 2rem;
                      line-height: 2.4rem;
                    }
                    @include media("<=phone") {
                      font-size: 1.7rem;
                      line-height: 2.2rem;
                      margin-top: 8px;
                    }
                    &:after {
                        content: "";
                        // position: absolute;
                        width: 100%;
                        height: 2px;
                        display: block;
                        margin-top: 5px;
                        left: 0;
                        background: $orange_v01;
                        transition: 0.4s ease;
                      }
                }
            }       
        }
    }
    .pattern{
        height: 50vh;
        height: calc(var(--vh, 1vh) * 50);
        width: 100%;
        background-size: 70%;
        background-position: center;
        background-repeat: repeat;
        // position: relative;
        // left: 0;
        // top: 0;
        // z-index: 6;

    }
    .background{
        // z-index: 20;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50vh;
    }

    .stacks{

      display: flex;
      height: 100%;
      padding: 0 32px;
      @include media("<=tablet") {
        padding: 0 16px;
      }
      @include media("<=phone") {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
      }
      align-items: flex-end;
      justify-content: space-between;


    }

    .stack{
        // position: relative;
        // background-color: coral;
        width: 33%;
        // height: 50vh;
        align-content: center;
        justify-content: space-between;

        display: flex;
        .svg{
          margin: 0 auto;
        }
        @include media("<=phone") {
          align-content: center;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: center;
          .svg{
            margin: 0 auto;
          }
        }
    }
    @include media("<=phone") {

      .stack:not(:nth-child(1)){
        display: none;
      }
      .stack:nth-child(1){
        width: 100%;
        // background: coral;
        height: 250px;
      }

    }


    .construction{
      // position: absolute;
      // // background: brown;
      // left: 50%;
      // translate: 50px;
      color: $orange;
      position: absolute; 
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
      // width: 200px; /* Need a specific value to work */
      z-index: 10;

      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      align-items: center;

      @include media("<=phone") {

        flex-direction: column;
        margin: 10px 20px;

      }

      div{
        
        margin: 2px 20px;
        a{
          text-decoration: none;
          color: $black;
          display: block;
        }
          .brochure{
            color: $orange;
          }
      }
    }

}