//@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@import "./varibles.scss";
@import "./breakpoints.scss";
@import "./components/header.scss";
@import "./components/banner.scss";
@import "./components/steps.scss";
@import "./components/navigation.scss";

body,
html {
  user-select: none;
  background: $white;
  overscroll-behavior: none;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  // font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
  //   "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  //   "Helvetica Neue", sans-serif;
  // font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
  // "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  // "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }
  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }
  @include media("<=1080px") {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @include media("<=phone") {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .bottom-align{
    align-items: flex-end;
  }

  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    height: 100%;
  }
}

// Intro overlay

.intro-overlay {
  .top {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    width: 100%;
    z-index: 8;
    .overlay-top {
      position: absolute;
      height: 100%;
      width: 33.333vw;
      background: $black;
      bottom: 0;
      left: 0;
      right: 0;
      &:nth-child(2){
        left: 33.333%;
        background: $black;
      }
      &:nth-child(3){
        left: 66.666%;
        background: $black;
      }
    }
  }
}


.App {
  z-index: 2;
  transform: translateY(0);
  position: relative;
}

.page {
  height: 100vh;
  width: 100%;
  background: $white;
  .row {
    display: flex;
    align-items: center;
  }
}

.page_1{
  height: 100vh;
  width: 100%;
  background: $white;
  .row {
    height: 50vh;
    display: flex;
    align-items: center;
    
  }
  .services{
    width: 100vw;
    display: flex;
    // height: 50vh;
    // flex-wrap: wrap;
    justify-content: space-between;

    .service{
      background-color: white;
      // width: 30%;
      min-width: 300px;
      height: 100%;

      // margin: 0 10px;
      // padding: 0 20px;
      margin: 10px;
      padding: 10px;


      box-shadow:  15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
      border-radius: 16;
      h4{

      }
      ul{
        font-size: small;
        padding: 0 10px;

      }
    }
  }
}
